import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../constants/route.constant';
import { ApplicationStateService } from '../../../services/application-state.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'goatsports-notifications-list-item',
  templateUrl: './notifications-list-item.component.html',
  styleUrls: ['./notifications-list-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TranslocoLocaleModule,
    TooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListItemComponent {
  @Input() notification!: any;
  @Output() removeNotification = new EventEmitter<string>();
  clientUid!: string;

  constructor(
    private router: Router,
    private applicationStateService: ApplicationStateService,
  ) {}

  navigateToChat(uid: string | undefined) {
    /* this.router.navigate([`/${RouteConstants.CHAT_ROUTE}`], {
      queryParams: { uid: uid },
    }); */
    this.applicationStateService.selectedChatGroupUidChanged(uid);
    this.router.navigate([`/${RouteConstants.CHAT_ROUTE}`]);
  }

  navigateToWorkout(uid: string) {
    const ruta =
      '/' +
      RouteConstants.CLIENTS_ROUTE +
      '/' +
      this.notification.clientUid +
      '/' +
      RouteConstants.CLIENTS_OVERVIEW_ROUTE +
      '/' +
      uid;
    this.router.navigate([ruta]);
  }

  remove(uid: string) {
    this.removeNotification.emit(uid);
  }
}
