import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs';
import {
  NotificationCreatedEvent,
  NotificationService,
} from '@goatsports/core/data-access';
import { ApiEventsService } from '../../services/api-events.service';
import { LoadingService } from '@goatsports/shared/util';
import * as _ from 'lodash-es';
import { DateTime } from 'luxon';
import { ApplicationStateService } from '../../services/application-state.service';

export interface RemoveNotificationModel {
  uid?: string;
  createdDate?: string;
}

@Injectable()
export class NotificationsListService {
  private _currentPage!: number;
  private _totalNotificationsCount!: number;
  private _currentNotificationsCount = 0;
  private _removedNotificationUid!: string;
  private _previousNotificationUid!: string;
  readonly DEFAULT_PAGE_SIZE = 8;
  private notifications: Record<string, NotificationCreatedEvent[]> = {};

  private _pageNumber = new BehaviorSubject<number>(1);
  private pageNumber$ = this._pageNumber.asObservable();

  private _notificationsLoaded = new BehaviorSubject<boolean>(false);
  notificationsLoaded$ = this._notificationsLoaded.asObservable();
  firstCall = true;

  constructor(
    private notificationService: NotificationService,
    private apiEventsService: ApiEventsService,
    private applicationStateService: ApplicationStateService,
    private loadingService: LoadingService,
  ) {}

  fetchData() {
    return combineLatest([
      this.getNotifications(),
      this.addWorkoutNotification(),
      this.processNotificationRemoved(),
    ]).pipe(
      map(() => (_.isEmpty(this.notifications) ? null : this.notifications)),
    );
  }

  get currentPage() {
    return this._pageNumber.value;
  }

  set pageNumber(pageNumber: number) {
    this._pageNumber.next(pageNumber);
  }

  removeNotification(uid: string, createdDate: string) {
    return this.notificationService.notificationUidPatch(uid, {}).pipe(
      tap(() =>
        this.applicationStateService.refreshNotifications({
          uid,
          createdDate,
        }),
      ),
    );
  }

  private addWorkoutNotification() {
    return this.apiEventsService.notificationCreated$.pipe(
      tap((notification) => {
        if (
          notification.uid &&
          notification.uid !== this._previousNotificationUid &&
          !_.isEmpty(notification)
        ) {
          this.updateNotifications(
            _.groupBy([notification], this.getGroupByMonth),
          );
          this._previousNotificationUid = notification.uid;
        }
      }),
    );
  }

  private getNotifications() {
    return this.pageNumber$.pipe(
      switchMap((pageNumber) => {
        return this.loadingService.showLoadingIndicator(
          this.notificationService
            .notificationGet(pageNumber, this.DEFAULT_PAGE_SIZE)
            .pipe(
              tap((res) => {
                if (this._currentPage !== pageNumber) {
                  this._currentNotificationsCount +=
                    res.notifications?.length ?? 0;
                }

                this._totalNotificationsCount = res.totalCount ?? 0;
                this._currentPage = pageNumber;
              }),
              map(
                ({ notifications }) =>
                  notifications as NotificationCreatedEvent[],
              ),
              tap((notifications) => {
                this.updateNotifications(
                  _.groupBy(notifications, this.getGroupByMonth),
                );
                setTimeout(() => {
                  if (!this.firstCall && this.currentPage !== 1) {
                    // Only trigger _groupsLoaded.next(true) if it's not the first call
                    this._notificationsLoaded.next(true);
                  } else {
                    this.firstCall = false; // Set firstCall to false after the initial call
                  }
                }, 0);
              }),
              // shareReplay()
            ),
        );
      }),
    );
  }

  private updateNotifications(
    notifications: Record<string, NotificationCreatedEvent[]>,
  ) {
    // First, merge the old and new notifications into one object
    const merged = _.mergeWith(
      {},
      this.notifications,
      notifications,
      (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
        // If not an array, return undefined to indicate to lodash to use the default merge behavior
        return undefined;
      },
    );

    // Then, for each key in the merged object, remove duplicates
    for (const key in merged) {
      // eslint-disable-next-line no-prototype-builtins
      if (merged.hasOwnProperty(key)) {
        // Create a map to track seen ids
        const seen = new Map();
        merged[key] = merged[key].filter(
          (notification: NotificationCreatedEvent) => {
            const duplicate = seen.get(notification.uid);
            seen.set(notification.uid, true);
            return !duplicate;
          },
        );
      }
    }
    // Finally, assign the deduplicated object back to this.notifications
    this.notifications = merged;
  }

  private getMonth(date: string) {
    return DateTime.fromISO(date).startOf('month').toFormat('MMMM, yyyy');
  }

  private getGroupByMonth = ({
    createdDateTimeUTC,
  }: NotificationCreatedEvent) => this.getMonth(createdDateTimeUTC as string);

  private processNotificationRemoved() {
    return this.applicationStateService.refreshNotifications$.pipe(
      tap((removedNotification) =>
        this.removeNotificationByUid(removedNotification),
      ),
    );
  }

  private removeNotificationByUid(
    removedNotification: RemoveNotificationModel | undefined,
  ) {
    if (!removedNotification) return;
    const { uid, createdDate } = removedNotification;
    if (
      !uid ||
      !createdDate ||
      DateTime.fromISO(createdDate).year === 1970 ||
      this._removedNotificationUid === uid
    ) {
      return;
    }

    this._removedNotificationUid = uid;

    const month = this.getMonth(createdDate);
    this.notifications[month] = (this.notifications[month] || []).filter(
      (notification) => notification.uid !== uid,
    );
    this._totalNotificationsCount--;
    this._currentNotificationsCount--;

    if (
      this._currentNotificationsCount === 0 &&
      this._totalNotificationsCount > 0
    ) {
      this._pageNumber.next(1);
    }
  }
}
