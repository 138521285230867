import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  NotificationsListService,
  RemoveNotificationModel,
} from './notifications-list.service';
import {
  EmptyObjectPipe,
  LoadingService,
  ScrollDirective,
} from '@goatsports/shared/util';
import { Subject, exhaustMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { SharedModule } from '../../shared.module';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { EmptyNotificationsPipe } from './empty-notifications.pipe';
import { NotificationsListItemComponent } from './notifications-list-item/notifications-list-item.component';
@Component({
  selector: 'goatsports-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ListViewModule,
    TranslocoModule,
    SharedModule,
    InfiniteScrollDirective,
    TooltipModule,
    CommonModule,
    EmptyObjectPipe,
    EmptyNotificationsPipe,
    TranslocoLocaleModule,
    ScrollDirective,
    LoadingSpinnerComponent,
    NotificationsListItemComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationsListService],
})
export class NotificationsListComponent implements OnInit, AfterViewInit {
  @ViewChild(ScrollDirective) scrollDirective!: ScrollDirective;
  @ViewChild('notifications')
  notificationsContainer!: ElementRef<HTMLDivElement>;
  notifications$ = this.notificationsListService.fetchData();

  private _remove = new Subject<RemoveNotificationModel>();
  private remove$ = this._remove.asObservable();

  firstTime = true;

  constructor(
    private notificationsListService: NotificationsListService,
    public loadingService: LoadingService,
  ) {}

  ngOnInit() {
    this.remove$
      .pipe(
        exhaustMap(({ uid, createdDate }) => {
          return this.notificationsListService.removeNotification(
            uid as string,
            createdDate as string,
          );
        }),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.notificationsListService.notificationsLoaded$.subscribe((isLoaded) => {
      this.firstTime = false;
      if (!isLoaded) {
        // If notifications are not loaded yet, prepare for scrolling down
        this.scrollDirective?.prepareForDown();
      } else {
        // If notifications are loaded, restore the scroll position (scrolling up)
        this.scrollDirective?.restore();
      }
    });
  }

  removeNotification(uid: string, createdDate: string) {
    this._remove.next({ uid, createdDate });
  }

  onScrollDown() {
    this.notificationsListService.pageNumber =
      this.notificationsListService.currentPage + 1;
  }

  orderKeyValue() {
    return 1;
  }

  trackByUid(index: number, item: any): string {
    return item.uid as string;
  }
}
