import { Pipe, PipeTransform } from '@angular/core';
import { NotificationCreatedEvent } from '@goatsports/core/data-access';

@Pipe({
  name: 'emptyNotifications',
  standalone: true,
  pure: false,
})
export class EmptyNotificationsPipe implements PipeTransform {
  transform(notifications: Record<string, NotificationCreatedEvent[]> | null) {
    if (!notifications) {
      return true;
    }

    const areAllValuesEmpty = Object.values(notifications).every(
      (value) => value.length === 0,
    );
    return areAllValuesEmpty;
  }
}
