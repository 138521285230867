<div class="notification">
  <ng-container *transloco="let t">
    <h2>{{ t('Notifications') }}</h2>
    <ng-container *ngIf="{ notifications: notifications$ | async } as vm">
      <ng-container
        *ngIf="
          (loadingService.isLoading$ | async) === false || !firstTime;
          else loading
        "
      >
        <div
          #notifications
          class="notifications-wrapper"
          [scrollWindow]="false"
          appScrollDirective
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="150"
          (scrolled)="onScrollDown()"
        >
          <div
            *ngIf="
              !(vm.notifications | emptyNotifications);
              else noNotifications
            "
          >
            <div
              *ngFor="
                let notification of vm.notifications | keyvalue: orderKeyValue;
                trackBy: trackByUid
              "
            >
              <div *ngIf="notification.value.length >= 1">
                <p>
                  {{
                    notification.value.length >= 1
                      ? t((notification.key | fullDateToMonthDate).month) +
                        ', ' +
                        (notification.key | fullDateToMonthDate).year
                      : ''
                  }}
                </p>
                <goatsports-notifications-list-item
                  *ngFor="let data of notification.value"
                  [notification]="data"
                  (removeNotification)="
                    removeNotification($event, data.createdDateTimeUTC!)
                  "
                ></goatsports-notifications-list-item>
              </div>
            </div>
          </div>
          <ng-template #noNotifications>
            <div class="no-data">
              <p>
                {{ t('Any events needing your attention will be shown here.') }}
              </p>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #loading>
        <goatsports-loading-spinner></goatsports-loading-spinner>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
