<ng-container *transloco="let t">
  <div
    class="inner-card list-item e-list-wrapper e-list-multi-line e-list-avatar list-row"
  >
    <div class="content-align-center">
      <div class="date">
        <span class="number">{{
          notification?.createdDateTimeUTC | date: 'd'
        }}</span>
        <span class="day">{{
          notification?.createdDateTimeUTC | translocoDate: { month: 'short' }
        }}</span>
      </div>
      <div class="notification-text">
        <span
          >{{ notification?.clientFirstName }}
          {{ notification?.clientLastName }}</span
        >
        <div class="word-break-text">
          <div *ngIf="notification?.type === 'WorkoutMissed'">
            <p>{{ t('Missed last workout') }}</p>
            <div>
              <b
                class="workout-name"
                (click)="navigateToWorkout(notification.payload.userWorkoutUid)"
                >{{ notification.payload?.workoutName }}</b
              >
            </div>
          </div>
          <div *ngIf="notification?.type === 'WorkoutMoved'">
            <p>
              {{ t('Moved') }}
              {{
                notification.payload?.movedFromDateTimeUTC >
                notification.payload?.movedToDateTimeUTC
                  ? t('future')
                  : t('passed')
              }}
              {{ t('workout') }}
            </p>
            <div>
              <b
                class="workout-name"
                (click)="
                  navigateToWorkout(notification.payload?.userWorkoutUid)
                "
                >{{ notification.payload?.workoutName }}</b
              >
              {{ t('from') }}
              <b>
                <!-- {{
                notification.payload?.movedFromDateTimeUTC | date: 'E d'
              }} -->
                {{
                  (notification.payload?.movedFromDateTimeUTC!
                    | translocoDate: { month: 'short' }) +
                    ' ' +
                    (notification.payload?.movedFromDateTimeUTC!
                      | translocoDate: { day: 'numeric' })
                }}</b
              >, {{ t('to') }}
              <b>
                <!-- {{
                notification.payload?.movedToDateTimeUTC | date: 'E d'
              }} -->

                {{
                  (notification.payload?.movedToDateTimeUTC!
                    | translocoDate: { month: 'short' }) +
                    ' ' +
                    (notification.payload?.movedToDateTimeUTC!
                      | translocoDate: { day: 'numeric' })
                }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-icon">
      <span
        style="cursor: pointer; font-size: 24px; color: #fac022"
        class="e-icons e-comment-show remove"
        (click)="navigateToChat(notification.payload.groupUid)"
      >
      </span>
    </div>
    <div class="remove-icon">
      <ejs-tooltip
        opensOn="Hover"
        id="tooltip"
        [content]="t('Dismiss')"
        target="#dismiss"
        position="BottomLeft"
      >
        <img
          id="dismiss"
          class="remove"
          src="assets/icons/dismiss.svg"
          (click)="remove(notification?.uid)"
        />
      </ejs-tooltip>
    </div>
  </div>
</ng-container>
